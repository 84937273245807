import React from "react"
import enrollInfoStyles from './enroll-info.module.css'
import MyNavLink from './my-nav-link'
import PropTypes from "prop-types"

export default class EnrollInfo extends React.Component {
  static propTypes={
    color:PropTypes.string.isRequired,
    src:PropTypes.string.isRequired
  }
  render() {
    const color=this.props.color
    const src=this.props.src
    return(
        <div className={enrollInfoStyles.profileBody}>
          <div className={enrollInfoStyles.profileContainer}>
            <div className={enrollInfoStyles.enrollInfoContainer}>
              <div className={enrollInfoStyles.infoTitle}>报名详情</div>
              <div className={enrollInfoStyles.state}>已成功报名</div>
              <div className={enrollInfoStyles.event}>太湖马拉松-全马</div>
              <div className={enrollInfoStyles.borderLine}></div>
              <div className={enrollInfoStyles.racerEnrollInfo}>
                <div className={enrollInfoStyles.racerEnrollInfoTitle}>
                  <div className={enrollInfoStyles.titleVerticalLine}></div>
                  <div>报名信息</div>
                  <MyNavLink to={src} style={{marginLeft:'20px',color:color,textDecoration:'underline'}}>查看详细报名信息</MyNavLink>
                </div>
                <div>
                  <div className={enrollInfoStyles.infoItemLeft}>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>姓名:</div>
                      <div className={enrollInfoStyles.item2}>张迈</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>参赛号:</div>
                      <div className={enrollInfoStyles.item2}>暂无</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>枪声成绩:</div>
                      <div className={enrollInfoStyles.item2}>暂无</div>
                    </div>
                  </div>
                  <div  className={enrollInfoStyles.infoItemRight}>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>证件号码:</div>
                      <div className={enrollInfoStyles.item2}>130888199802092223</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>报名确认函:</div>
                      <div className={enrollInfoStyles.item2}>暂无</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>成绩证书:</div>
                      <div className={enrollInfoStyles.item2}>暂无</div>
                    </div>
                  </div>
                  <div style={{clear:'both'}}></div>
                </div>
              </div>
              <div className={enrollInfoStyles.borderLine}></div>
              <div className={enrollInfoStyles.orderInfo}>
                <div className={enrollInfoStyles.orderInfoTitle}>
                  <div className={enrollInfoStyles.titleVerticalLine}></div>
                  <div >订单信息</div>
                </div>
                <div>
                  <div className={enrollInfoStyles.infoItemLeft}>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>订单信息:</div>
                      <div className={enrollInfoStyles.item2}>xxxxxxxxxxxxxxxx</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>下单时间:</div>
                      <div className={enrollInfoStyles.item2}>2019-11-08 10:30</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>支付时间:</div>
                      <div className={enrollInfoStyles.item2}>2019-11-08 10:35</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>支付订单号:</div>
                      <div className={enrollInfoStyles.item2}>--</div>
                    </div>
                  </div>
                  <div className={enrollInfoStyles.infoItemRight}>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>订单金额:</div>
                      <div className={enrollInfoStyles.item2}>￥130</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>订单状态:</div>
                      <div className={enrollInfoStyles.item2}>报名成功</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}>支付方式:</div>
                      <div className={enrollInfoStyles.item2}>支付宝</div>
                    </div>
                    <div className={enrollInfoStyles.infoLine}>
                      <div className={enrollInfoStyles.item1}></div>
                      <div className={enrollInfoStyles.item2}></div>
                    </div>
                  </div>
                  <div style={{clear:'both'}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}